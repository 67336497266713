import * as React from 'react'
import styled from '../../styles/styled'

interface Material {
  src: string
  text: string
  description: string
  reference: {
    title: string
    year: string
  }
}

interface MaterialProps {
  materials: Material[]
}

const ListContainer = styled.div`
  display: flex;
`

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`

const Description = styled.p`
  font-weight: bold;
  margin-bottom: 3px;
`

const ItemLink = styled.a`
  display: flex;
`

const Reference = styled.p`
  display: flex;
`
const Italic = styled.span`
  font-style: italic;
`

const Materials: React.FC<MaterialProps> = ({ materials }: MaterialProps) => {
  return (
    <ListContainer>
      <ol>
        {materials.map(data => (
          <li key={data.src}>
            <ItemContainer>
              <Description>{data.description}</Description>
              <ItemLink href={data.src}>{data.text}</ItemLink>
              <Reference>
                (<Italic>{data.reference.title}</Italic>, {data.reference.year})
              </Reference>
            </ItemContainer>
          </li>
        ))}
      </ol>
    </ListContainer>
  )
}

export default Materials
