import { dimensions, fonts, colors, breakpoints } from './variables'
import { getEmSize } from './mixins'

export default `
  html {
    font-size: ${dimensions.fontSize.regular}px;
    line-height: 1.6;
    @media (min-width: ${breakpoints.xxl}px) {
      font-size: 21px;
    }
  }
  body {
    font-family: ${fonts.baseFont};
    color: ${colors.white};
    background-color: ${colors.white};
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    font-size: ${dimensions.fontSize.regular}px;
    line-height: 1.6;
    @media (min-width: ${breakpoints.xxl}px) {
      font-size: 21px;
    }
  }

  .gatsby-resp-image-wrapper, .project-accordion-container  img:hover {
    cursor: pointer;
    cursor: zoom-in;
  }

  p {
    margin-bottom: 1.6rem;
  }

  @keyframes bgani {
    0%   { background-size: 100% auto; }
    50% { background-size: 92% auto; }
    100% { background-size: 100% auto;}
  }
  @keyframes bgmove {
    0% { background-position: 0 15px; }
    50% { background-position: 15px 15px; }
    100% { background-position: 0 15px; }
  }

  .marker-australia path {
    fill: #EEF705;
  }

  .marker-brazil path {
    fill: #F71AAB;
  }

  .marker-fiji path {
    fill: #15C8F9;
  }

  .marker-india path {
    fill: #0EEF2F;
  }
  .marker-yemen path {
    fill: #FF6429;
  }

  .cc-elements {
    list-style: none;
    margin-bottom: 25px;
    margin-top: -15px;
  }

  .cc-elements img {
    position: relative;
    top: -3px;
    width: 40px;
    vertical-align: middle;
  }

  .cc-elements abbr {
    display: inline-block;
    width: 20px;
  }

  .imprint-template p {
    max-width: 800px;
  }
`
