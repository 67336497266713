import React, { useContext } from 'react'
import styled from '../styles/styled'
import { ConsentContext } from './layouts'
import { MultimediaConsent } from './MultimediaConsent'

interface VideoProps {
  videoSrcURL: string
  videoTitle?: string
}

const IframeContainer = styled.div`
  width: 100%;
  height: 400px;
`

const Video: React.SFC<VideoProps> = ({ videoSrcURL, videoTitle }) => {
  const { multimedia } = useContext(ConsentContext)

  if (!multimedia) {
    return <MultimediaConsent />
  }

  return (
    <IframeContainer>
      <iframe
        data-name="multimedia"
        src={videoSrcURL}
        title={videoTitle}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        allowFullScreen
        style={{ border: '0px', position: 'relative', width: '100%', height: '100%' }}
      />
    </IframeContainer>
  )
}
export default Video
