import * as React from 'react'
import styled from '../../../styles/styled'
import { TemperatureData } from '../../../typings'
import { useTranslation } from 'react-i18next'

interface TempProps {
  temperatures: TemperatureData[]
  id: string
}

const TemperatureContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`

const TempItem = styled.div`
  display: flex;
  margin-bottom: 1px;
`

const TempAttribute = styled.span`
  text-transform: uppercase;
  font-weight: bold;
  padding-right: 5px;
`

const TempValue = styled.span`
  color: green;
  font-weight: bold;
`

const Temperature: React.FC<TempProps> = ({ temperatures, id }: TempProps) => {
  const { t } = useTranslation()

  return (
    <TemperatureContainer>
      <h1>{id}</h1>
      {temperatures.map(temp => (
        <TempItem key={temp.type}>
          <TempAttribute>{t(`info.climate.${temp.type}`)}:</TempAttribute>
          <TempValue>{temp.value}</TempValue>
        </TempItem>
      ))}
    </TemperatureContainer>
  )
}

export default Temperature
