import * as React from 'react'
import styled from '../styles/styled'
import Container from './layouts/Container'
import { IndexLayoutQuery } from '../graphql-types'
import LocalizedLink from './translations/LocalizedLink'
// @ts-ignore
import hawlogo from '../images/HAW-Logo.svg'
// @ts-ignore
import hooulogo from '../images/HOOU-Logo.svg'

const StyledFooter = styled.footer`
  height: ${(props) => props.theme.heights.footer}px;
  background-color: #fff;
  color: #4a4a4a;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  @media (min-width: ${(props) => props.theme.breakpoints.xxl}px) {
    height: 55px;
  }
`

const FooterInner = styled(Container)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
`

const FooterLogos = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0;
  line-height: 0;
  div {
    flex: 0 0 auto;
    padding: 0 15px;
    &:first-of-type {
      padding-left: 0;
    }
  }
`
const FooterNav = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const FooterLink = styled(LocalizedLink)`
  display: flex;
  color: ${(props) => props.theme.colors.darktext};
  font-size: 0.6875rem;
  text-transform: uppercase;
  margin-left: 18px;
  letter-spacing: 1.1px;
  &:hover,
  &:focus {
    text-decoration: none;
  }
`

const FooterLinkCookies = styled.a`
  display: flex;
  color: ${(props) => props.theme.colors.darktext};
  font-size: 0.6875rem;
  text-transform: uppercase;
  margin-left: 18px;
  letter-spacing: 1.1px;
  &:hover,
  &:focus {
    text-decoration: none;
  }
`

interface FooterProps {
  imprintData?: IndexLayoutQuery['allMdx']['edges']
}

const Footer: React.FC<FooterProps> = ({ imprintData }) => {
  return (
    <StyledFooter>
      <FooterInner>
        <FooterLogos>
          <div>
            <img src={hawlogo} alt="ClimateHealthMap" />
          </div>
          <div>
            <img src={hooulogo} alt="ClimateHealthMap" />
          </div>
        </FooterLogos>
        <FooterNav>
          <React.Fragment>
            <FooterLinkCookies
              href="#"
              onClick={() => {
                if (typeof window !== 'undefined') {
                  window.klaro.show(config)
                }
              }}
            >
              Cookies
            </FooterLinkCookies>

            {imprintData &&
              imprintData.map(({ node }) => (
                <FooterLink key={node.id} to={node?.parent?.relativeDirectory}>
                  {node.frontmatter?.title || 'n/a'}
                </FooterLink>
              ))}
          </React.Fragment>
        </FooterNav>
      </FooterInner>
    </StyledFooter>
  )
}

export default Footer
