// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-templates-imprint-tsx": () => import("./../src/templates/imprint.tsx" /* webpackChunkName: "component---src-templates-imprint-tsx" */),
  "component---src-templates-intro-tsx": () => import("./../src/templates/intro.tsx" /* webpackChunkName: "component---src-templates-intro-tsx" */),
  "component---src-templates-project-detail-tsx": () => import("./../src/templates/project-detail.tsx" /* webpackChunkName: "component---src-templates-project-detail-tsx" */),
  "component---src-templates-project-info-tsx": () => import("./../src/templates/project-info.tsx" /* webpackChunkName: "component---src-templates-project-info-tsx" */)
}

