import ByNcSa from "../../../../src/images/by-nc-sa.svg";
import BySa from "../../../../src/images/by-sa.svg";
import By from "../../../../src/images/CC-BY_icon.svg";
import Creator from "../../../../src/images/CC-BY-creator_icon.svg";
import Adaptions from "../../../../src/images/CC-BY-Adaptions_icon.svg";
import ByNc from "../../../../src/images/by-nc.svg";
import ByNd from "../../../../src/images/by-nd.svg";
import NonCommercial from "../../../../src/images/CC-NonCommercial.svg";
import NoDerivatives from "../../../../src/images/CC-ND.svg";
import * as React from 'react';
export default {
  ByNcSa,
  BySa,
  By,
  Creator,
  Adaptions,
  ByNc,
  ByNd,
  NonCommercial,
  NoDerivatives,
  React
};