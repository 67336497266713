import * as React from 'react'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { graphql, StaticQuery } from 'gatsby'

import 'modern-normalize'
import '../../styles/normalize'
import '../../styles/basic'

import Footer from '../Footer'
import Header from '../Header'
import LayoutRoot from './LayoutRoot'
import LayoutMain from './LayoutMain'
import { IndexLayoutQuery } from '../../graphql-types'
import LoadableProjectMap from '../projects/Map/LoadableMap'
import i18n from '../../i18n'
import { useTranslation } from 'react-i18next'
import { klaroConfig } from '../../klaro'

export interface Locale {
  locale: 'de' | 'en'
}

type Project = string | null

interface LayoutProps {
  path: string
  pageContext: {
    locale: 'de' | 'en'
    pagePath: string
    project: Project
    title: string
  }
}

const titleByPath = (path: string): string => {
  const { t } = useTranslation()

  return path.includes('projects') ? t('pages.projects') : t('pages.start')
}

const buildTitle = (path: string, title?: string): string => {
  return title ? title : titleByPath(path)
}

const LocaleContext = React.createContext<Locale>({ locale: 'en' })
const ProjectContext = React.createContext<Project>(null)
const ConsentContext = React.createContext<{ multimedia: boolean }>({ multimedia: false })
// Use the built-in Context API to make the "locale" available to every component in the tree
// This e.g. enables the LocalizedLink to function correctly
// As this component wraps every page (due to the wrapPageElement API) we can be sure to have
// the locale available everywhere!
const Layout: React.FC<LayoutProps> = ({ children, pageContext: { pagePath, locale, project, title } }) => {
  const [consents, setConsents] = React.useState({ multimedia: false })
  useEffect(() => {
    i18n.changeLanguage(locale).then()
    if (window.config) {
      window.config.lang = locale
    }
  }, [locale])

  useEffect(() => {
    if (window !== undefined) {
      window.config = klaroConfig
      window.config.lang = locale
      if (!window.klaro) {
        const script = document.createElement('script')
        script.src = '/klaro/dist/klaro.js'
        script.onload = () => {
          const manager = window.klaro.getManager(window.config)
          if (!manager.confirmed) {
            window.klaro.show(klaroConfig, false)
          }
          setConsents(manager.states)
        }
        document.head.appendChild(script)
      }
    }
  }, [])

  return (
    <ConsentContext.Provider value={consents}>
      <ProjectContext.Provider value={project}>
        <LocaleContext.Provider value={{ locale }}>
          <StaticQuery
            query={graphql`
              query IndexLayout {
                site {
                  siteMetadata {
                    title
                    description
                    keywords
                  }
                }
                sitePage {
                  path
                }
                allMdx(filter: { frontmatter: { template: { eq: "imprint" } } }) {
                  edges {
                    node {
                      id
                      frontmatter {
                        title
                      }
                      fields {
                        locale
                      }
                      parent {
                        ... on File {
                          relativeDirectory
                        }
                      }
                    }
                  }
                }
              }
            `}
            render={(data: IndexLayoutQuery) => {
              const localizedImprintData = data.allMdx?.edges.filter(({ node }) => node.fields?.locale === locale)
              const sortedAndLocalizedImprintData = ['privacy-policy', 'imprint'].map((topic) =>
                localizedImprintData.find((item) => item?.node?.parent?.relativeDirectory! === `imprint/${topic}`)
              )
              return (
                <LayoutRoot>
                  <Helmet
                    title={buildTitle(pagePath, title)}
                    meta={[
                      { name: 'description', content: data!.site!.siteMetadata!.description! },
                      { name: 'keywords', content: data!.site!.siteMetadata!.keywords! }
                    ]}
                  />
                  <Header currentPath={pagePath} />
                  <LayoutMain>
                    <LoadableProjectMap currentPath={pagePath} />
                    {/*<LoadableSimpleReactLightbox>{children}</LoadableSimpleReactLightbox>*/}
                    {children}
                  </LayoutMain>
                  <Footer imprintData={sortedAndLocalizedImprintData} />
                </LayoutRoot>
              )
            }}
          />
        </LocaleContext.Provider>
      </ProjectContext.Provider>
    </ConsentContext.Provider>
  )
}

export { Layout, LocaleContext, ProjectContext, ConsentContext }
