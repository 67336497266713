import * as React from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

type Props = {}

const ConsentPanel = styled.div`
  background-color: rgba(6, 117, 183, 0.5);
  padding: 25px;
  border: yellow 1px solid;

  strong {
    font-weight: bolder;
    text-transform: uppercase;
  }

  a {
    color: white;
    text-decoration: underline;
  }

  a.settings {
    color: yellow;
    padding-left: 20px;
  }
`

const MyButton = styled.div`
  display: inline-block;
  cursor: 'pointer';
  background: ${(props) => props.theme.colors.yellow};
  text-align: center;
  border-radius: 3px;
  font-family: ${(props) => props.theme.fonts.headerFont};
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: ${(props) => props.theme.colors.black};
  font-size: 13px;
  padding: 12px 15px;
  @media (min-width: ${(props) => props.theme.breakpoints.xxl}px) {
    font-size: 18px;
    padding: 12px 35px;
  }
  &:hover,
  &:focus {
    text-decoration: none;
  }
`

const giveMultimediaConsent = () => {
  if (window && window.klaro) {
    const manager = window.klaro.getManager(window.config)
    manager.updateConsent('multimedia', true)
    manager.saveAndApplyConsents()
  }
}

const openConsentManager = () => {
  if (window && window.klaro) {
    window.klaro.show(window.config, true)
  }
}

export const MultimediaConsent: React.SFC<Props> = (props) => {
  const { t } = useTranslation()

  return (
    <ConsentPanel>
      <h2>{t('consent.headline')}</h2>
      <strong>{t('consent.subline')}</strong>
      <p>{t('consent.description')}</p>
      <p dangerouslySetInnerHTML={{ __html: t('consent.more') }} />
      <div></div>
      <MyButton style={{ cursor: 'pointer' }} onClick={() => giveMultimediaConsent()}>
        {t('consent.give-consent')}
      </MyButton>{' '}
      <a className="settings" style={{ cursor: 'ponter' }} href="#consent" onClick={() => openConsentManager()}>
        {t('consent.open-settings')}
      </a>
    </ConsentPanel>
  )
}
