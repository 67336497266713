import React, { useContext } from 'react'
import loadable from '@loadable/component'
import { H5pProps } from './H5p'
import { ConsentContext } from '../../layouts'
import { MultimediaConsent } from '../../MultimediaConsent'

const H5p = loadable(() => import('./H5p'), {
  fallback: <div>Loading...</div>
})

const LoadedH5p: React.FC<H5pProps> = ({ workspace }: H5pProps) => {
  const { multimedia } = useContext(ConsentContext)

  if (!multimedia) {
    return <MultimediaConsent />
  }

  if (typeof window === 'undefined') {
    return null
  }
  return (
    <div>
      <H5p workspace={workspace} />
    </div>
  )
}

export default LoadedH5p
