export const klaroConfig = {
  elementID: 'klaro',
  storageMethod: 'cookie',
  storageName: 'klaro',
  htmlTexts: true,
  cookieExpiresAfterDays: 30,
  privacyPolicy: '/imprint/privacy-policy',
  default: true,
  mustConsent: false,
  acceptAll: true,
  hideDeclineAll: false,
  hideLearnMore: false,
  translations: {
    de: {
      consentNotice: {
        extraHTML: ''
      },
      consentModal: {
        description:
          'Wir benötigen Ihre Zustimmung für die Verwendung von multimedialen Inhalten, die wir über Dienste Dritter einbinden, wodurch ggf. Datenschutzrechtliche.'
      },

      multimedia: {
        description:
          'Wir verwenden YouTube als Video-Player (auch in Fallstudien und Quizes) im erweitertem Datenschuztmodus. Durch Einbettung können pesonenbezogene Daten an Dritte erfolgen. Damit wir diese Inhalte anzeigen können benötige wir Ihre Zustimmung. Bitte beachten Sie hierzu unsere Datenschutzerklärung'
      },

      purposes: {
        multimedia: 'Multimedia (möglicherweise Marketing)'
      }
    },
    en: {
      consentModal: {
        description: 'We need your consent for the use of multimedia content, that are embedded with services from third parties.'
      },
      multimedia: {
        description:
          'We are using the YouTube video player (also in case studies and quizzes) in the extended privacy mode. Nonetheless it is possible that by embedding personal data is passed to third parties. We need your consent in order to display these medias. Please note our privacy policy.'
      },
      purposes: {
        multimedia: 'Multimedia (possibly Marketing)'
      }
    }
  },
  apps: [
    {
      name: 'multimedia',
      default: false,
      title: 'YouTube Video / H5P',
      purposes: ['multimedia'],
      cookies: [],
      callback: function (consent, app) {
        const current = localStorage.getItem('multimedia') === 'true'
        localStorage.setItem('multimedia', consent)

        if (current !== consent) {
          document.location.reload()
        }
      },
      required: false,
      optOut: false,
      onlyOnce: false
    }
  ]
}
