import * as React from 'react'
import styled from '../../styles/styled'
import { getEmSize } from '../../styles/mixins'

const StyledContainer = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  z-index: 100;
  @media (min-width: ${props => props.theme.breakpoints.sm}px) {
    max-width: ${props => getEmSize(props.theme.widths.sm)}rem;
  }
  @media (min-width: ${props => props.theme.breakpoints.md}px) {
    max-width: ${props => getEmSize(props.theme.widths.md)}rem;
  }
  @media (min-width: ${props => props.theme.breakpoints.lg}px) {
    max-width: ${props => getEmSize(props.theme.widths.lg)}rem;
  }
  @media (min-width: ${props => props.theme.breakpoints.xl}px) {
    max-width: ${props => getEmSize(props.theme.widths.xl)}rem;
  }
  @media (min-width: ${props => props.theme.breakpoints.xxl}px) {
    max-width: ${props => props.theme.widths.xxl}px;
  }
  padding: 0 ${props => props.theme.dimensions.containerPadding}rem;
`

interface ContainerProps {
  className?: string
}

const Container: React.FC<ContainerProps> = ({ children, className }) => {
  return <StyledContainer className={className}>{children}</StyledContainer>
}

export default Container
