/* eslint-disable max-len */
export interface Colors {
  lime: string
  brand: string
  lilac: string
  pink: string
  accent: string
  slider: {
    blue: string
    gray: string
  }
  ui: {
    light: string
    whisper: string
  }
  gray: {
    dark: string
    copy: string
    calm: string
  }
  white: string
  black: string
  dark: string
  darktext: string
  yellow: string
  green: string
  blue: string
  darkblue: string
  lightblue: string
  boxborder: string
  mainbg: string
}

export interface Fonts {
  sansSerif: string
  monospace: string
  headerFont: string
  baseFont: string
}

export interface Breakpoints {
  xs: number
  sm: number
  md: number
  lg: number
  xl: number
  xxl: number
}

export interface Widths {
  sm: number
  md: number
  lg: number
  xl: number
  xxl: number
}

export interface Dimensions {
  fontSize: {
    regular: number
    large: number
    enormous: number
    small: number
  }
  headingSizes: {
    h1: number
    h2: number
    h3: number
    h4: number
  }
  lineHeight: {
    regular: number
    heading: number
  }
  containerPadding: number
  bottomMargin: number
}

export interface Heights {
  header: number
  footer: number
  tabHeader: number
}

export const colors: Colors = {
  lime: '#8bc34a',
  brand: '#0675B7',
  lilac: '#9d7cbf',
  pink: '#ec07da',
  accent: '#424B5A',
  slider: {
    blue: '#2196f3',
    gray: '#5b5b5b'
  },
  ui: {
    light: '#f5f3f7',
    whisper: '#0a0a0a'
  },
  gray: {
    dark: 'hsla(270, 17.119554496%, 0%, 0.92)',
    copy: 'hsla(270, 15.797828016000002%, 0%, 0.88)',
    calm: 'rgba(0, 0, 0, 0.54)'
  },
  white: '#fff',
  black: '#000',
  dark: '#131127',
  darktext: '#4a4a4a',
  yellow: '#DEDC00',
  green: '#95C11F',
  blue: '#0675B7',
  darkblue: '#2465D3',
  lightblue: '#009FE3',
  boxborder: '#979797',
  mainbg: '#131127'
}

export const fonts: Fonts = {
  sansSerif: 'Roboto',
  monospace: 'Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace, monospace',
  headerFont: 'Poppins',
  baseFont: 'Roboto'
}

export const breakpoints: Breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1920
}

export const widths: Widths = {
  sm: 540,
  md: 720,
  lg: 960,
  xl: 1140,
  xxl: 1520
}

export const dimensions: Dimensions = {
  fontSize: {
    regular: 15,
    large: 18,
    enormous: 40,
    small: 11
  },
  headingSizes: {
    h1: 2.441,
    h2: 1.953,
    h3: 1.563,
    h4: 1.25
  },
  lineHeight: {
    regular: 1.6,
    heading: 1.2
  },
  containerPadding: 1,
  bottomMargin: 1.1
}

export const heights: Heights = {
  header: 65,
  footer: 45,
  tabHeader: 50
}
