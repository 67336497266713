import * as React from 'react'
import { css, Global } from '@emotion/core'
import styled from '../../styles/styled'
import normalize from '../../styles/normalize'
import basic from '../../styles/basic'
import { ThemeProvider } from 'emotion-theming'
import {
  Breakpoints,
  breakpoints,
  Colors,
  colors,
  Dimensions,
  dimensions,
  Fonts,
  fonts,
  Heights,
  heights,
  Widths,
  widths
} from '../../styles/variables'

const StyledLayoutRoot = styled.div`
  display: flex;
  height: 100vh;
  padding: 65px 0 45px;
  @media (min-width: ${breakpoints.xxl}px) {
    padding: 80px 0 55px;
  }
`

interface LayoutRootProps {
  className?: string
}

export type Theme = {
  colors: Colors
  fonts: Fonts
  breakpoints: Breakpoints
  widths: Widths
  dimensions: Dimensions
  heights: Heights
}

const theme: Theme = {
  colors,
  fonts,
  breakpoints,
  widths,
  dimensions,
  heights
}

const LayoutRoot: React.FC<LayoutRootProps> = ({ children, className }) => (
  <React.Fragment>
    <Global styles={() => css(normalize, basic)} />
    <StyledLayoutRoot className={className}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledLayoutRoot>
  </React.Fragment>
)

export default LayoutRoot
