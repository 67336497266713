import * as React from 'react'
import { Population } from '../../../typings'
import styled from '../../../styles/styled'

interface PopulationProps {
  population: Population
}

const PopulationContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${props => props.theme.dimensions.bottomMargin}rem;
`

const PopulationItem = styled.div`
  display: flex;
  white-space: pre-wrap;
`

const Source = styled.div`
  padding-top: 10px;
  font-size: smaller;
`

const PopulationStatistics: React.FC<PopulationProps> = ({ population }: PopulationProps) => {
  return (
    <PopulationContainer>
      {population.data.map((pop: { type: string | number | undefined; value: any }) => (
        <PopulationItem key={pop.type}>
          <strong>{`${pop.type}: `}</strong>
          {pop.value}
        </PopulationItem>
      ))}
      <Source>{population.source}</Source>
    </PopulationContainer>
  )
}

export default PopulationStatistics
