import * as React from 'react'
import { Link } from 'gatsby'
import { LocaleContext } from '../layouts'
// @ts-ignore
import i18n from '../../../config/i18n.js'
import styled from '../../styles/styled'
import { ReactNode } from 'react'

interface Props {
  to: string
  children?: ReactNode
  className?: string
}

const StyledLink = styled(Link)`
  &:hover,
  &:focus {
    text-decoration: none;
  }
`

export const removeTrailingSlash = (path: string): string => {
  if (path[0] === '/') {
    return path.slice(1)
  }

  return path
}

// Use the globally available context to choose the right path
const LocalizedLink: React.FC<Props> = ({ to, children, className }) => {
  const { locale } = React.useContext(LocaleContext)
  const treatedTo = removeTrailingSlash(to)
  const locales: Locales = i18n
  // If it's the default language, don't do anything
  // If it's another language, add the 'path'
  // However, if the homepage/index page is linked don't add the 'to'
  // Because otherwise this would add a trailing slash
  const path = locales[locale].default ? `/${treatedTo}` : `/${locales[locale].path}/${treatedTo}`

  return <StyledLink children={children} to={path} className={className} />
}

export default LocalizedLink
