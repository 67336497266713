import * as React from 'react'
import { Link } from 'gatsby'
import styled from '../../styles/styled'
import { removeTrailingSlash } from './LocalizedLink'
import { transparentize } from 'polished'
import { LocaleContext } from '../layouts'
import { ReactNode } from 'react'

interface StyledLinkProps {
  active: boolean
}
interface BasicLinkProps {
  className?: string
  to: string
  children?: ReactNode
}

const BasicLink = ({ className, to, children }: BasicLinkProps) => <Link className={className} to={to} children={children} />
const StyledLink = styled(BasicLink)<StyledLinkProps>`
  display: flex;
  color: ${props => (props.active ? props.theme.colors.white : transparentize(0.4, '#a1aeb7'))};
  margin-left: 15px;
  &:hover,
  &:focus {
    text-decoration: none;
    color: ${props => props.theme.colors.white};
  }
`

const StyledToggle = styled.div`
  display: flex;
  justify-content: flex-end;
  border-left: 1px solid #a1aeb7;
  padding-left: 30px;
`

interface Props {
  currentPath: string
}

const LanguageToggle: React.FC<Props> = ({ currentPath }) => {
  const { locale } = React.useContext(LocaleContext)
  const isActive = (lang: 'en' | 'de'): boolean => lang === locale

  return (
    <StyledToggle>
      <StyledLink to={`/${removeTrailingSlash(currentPath)}`} active={isActive('en')}>
        EN
      </StyledLink>
      <StyledLink to={`/de/${removeTrailingSlash(currentPath)}`} active={isActive('de')}>
        DE
      </StyledLink>
    </StyledToggle>
  )
}

export default LanguageToggle
