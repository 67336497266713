/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

require('typeface-roboto')
require('typeface-poppins')

import CustomLayout from './wrapPageElement'

export const wrapPageElement = CustomLayout
