import * as React from 'react'
import loadable from '@loadable/component'
interface MapProps {
  currentPath: string
}

const LoadedMap = loadable(() => import('./ProjectMap'), {
  fallback: <div>Loading...</div>
})

const LoadableMap: React.FC<MapProps> = ({ currentPath }: MapProps) => {
  return <LoadedMap currentPath={currentPath} />
}

export default LoadableMap
