import * as React from 'react'
import styled from '../../styles/styled'

const StyledLayoutMain = styled.main`
  display: flex;
  flex: 1 1 auto;
  background: #131127;
  height: 100%;
  z-index: 1;
  overflow: auto;
  position: relative;
`

interface LayoutMainProps {
  className?: string
}

const LayoutMain: React.FC<LayoutMainProps> = ({ children, className }) => (
  <StyledLayoutMain className={className}>{children}</StyledLayoutMain>
)

export default LayoutMain
