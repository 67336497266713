import * as React from 'react'
import styled from '../styles/styled'
import { transparentize } from 'polished'
import Container from './layouts/Container'
import LanguageToggle from './translations/LanguageToggle'
import LocalizedLink from './translations/LocalizedLink'
// @ts-ignore
import logo from '../images/Logo.svg'
import { useTranslation } from 'react-i18next'

const StyledHeader = styled.header`
  height: ${props => props.theme.heights.header}px;
  background-color: ${props => props.theme.colors.dark};
  color: ${props => transparentize(0.5, props.theme.colors.white)};
  flex-shrink: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  font-size: 15px;
  @media (min-width: ${props => props.theme.breakpoints.xxl}px) {
    height: 80px;
    font-size: 17px;
  }
  hr {
    margin: 0;
    padding: 0;
    height: 0;
    border-top: 1px solid ${props => transparentize(0.5, props.theme.colors.accent)};
  }
  .logoLinkWrap {
    height: 54px;
    @media (min-width: ${props => props.theme.breakpoints.xxl}px) {
      height: 70px;
    }
  }
  .logoLink {
    height: 22px;
    margin: 16px 0;
    @media (min-width: ${props => props.theme.breakpoints.xxl}px) {
      height: 30px;
      margin: 20px 0;
    }
  }
`

const HeaderInner = styled(Container)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px ${props => props.theme.dimensions.containerPadding}rem;
`

interface HeaderProps {
  currentPath: string
}

interface LinkData {
  type: 'about' | 'tutorial' | 'background'
  to: string
}

const MenuContainer = styled.div`
  display: flex;
`

const LocalizedMenuLink = styled(LocalizedLink)<{ active: boolean }>`
  color: ${props => (props.active ? 'orange' : '#a1aeb7')};
  padding: 15px;
  text-transform: upperCase;
  &:hover,
  &:focus {
    text-decoration: none;
    color: #fff;
  }
`

const Menu: React.FC<HeaderProps> = ({ currentPath }) => {
  const links: LinkData[] = [
    { type: 'background', to: '/intro/background/' },
    { type: 'about', to: '/intro/about-us/' },
    { type: 'tutorial', to: '/intro/tutorial/' }
  ]

  const { t } = useTranslation()

  const isActive = (path: string): boolean => currentPath.includes(path)

  return (
    <MenuContainer>
      {links.map(link => (
        <LocalizedMenuLink key={link.type} to={link.to} active={isActive(link.to)}>
          {t(`header.${link.type}`)}
        </LocalizedMenuLink>
      ))}
    </MenuContainer>
  )
}
const Header: React.FC<HeaderProps> = ({ currentPath }) => {
  const menuVisible: boolean = currentPath.includes('projects')

  return (
    <StyledHeader>
      <HeaderInner>
        <LocalizedLink to="/" className="logoLinkWrap">
          <img src={logo} alt="ClimateHealthMap" className="logoLink" />
        </LocalizedLink>
        {menuVisible && <Menu currentPath={currentPath} />}
        <LanguageToggle currentPath={currentPath} />
      </HeaderInner>
      <hr />
    </StyledHeader>
  )
}

export default Header
