import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
// @ts-ignore
import de from '../locales/de/translations.json'
// @ts-ignore
import en from '../locales/en/translations.json'

i18next.use(initReactI18next).init({
  fallbackLng: 'en',
  resources: {
    de,
    en
  },
  ns: ['translations'],
  defaultNS: 'translations',
  returnObjects: true,
  debug: process.env.NODE_ENV === 'development',
  interpolation: {
    escapeValue: false
  },
  react: { useSuspense: false }
})
i18next.languages = ['en', 'de']

export default i18next
